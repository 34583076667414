import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Subheader } from "components";
import styled from "styled-components";

export const Loading = () => {
  return (
    <StyledLoading>
      <Container>
        <Row>
          <Col>
            <Subheader>Loading...</Subheader>
          </Col>
        </Row>
      </Container>
    </StyledLoading>
  );
};

const StyledLoading = styled.div`
  text-align: center;
  height: 80vh;
`;
