import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext, EmailCapture, styles } from "components";
import styled from "styled-components";

export const SplashPage = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/shift-dashboard");
  }, [navigate, user]);

  return (
    <StyledHomePage>
      <EmailCapture headline={"Join the Beta"} category="homecare-0.1" />
    </StyledHomePage>
  );
};

const { spacing } = styles;
const StyledHomePage = styled.div`
  padding: ${spacing.doubleSpace} 0;
`;
