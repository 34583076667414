import React from "react";

import { SectionHeader, SectionSubheader, styles } from "components";
import styled from "styled-components";

export const ThreePartHeader = ({ subtitle, title, type, ...rest }) => {
  return (
    <StyledThreePartHeader {...rest}>
      <SectionSubheader>{subtitle}</SectionSubheader>
      <SectionHeader>{title}</SectionHeader>
      <SectionDivider className={`divider ${type}`} />
    </StyledThreePartHeader>
  );
};

const { colors, spacing } = styles;
const StyledThreePartHeader = styled.div`
  text-align: center;
  h2 {
    margin: 0 auto ${spacing.doubleSpace};
  }
  h4 {
    margin-bottom: ${spacing.singleSpace};
    font-weight: 600;
  }
  .divider {
    margin-bottom: ${spacing.tripleSpace};
  }
`;

const SectionDivider = styled.div`
  height: 4px;
  background-color: ${colors.blue};
  width: 165px;
  margin: 0 auto;

  &.white {
    background-color: white;
    height: 3px;
  }
`;
