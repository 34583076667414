import React from "react";

import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { formatName } from "utils";

import { CloseIcon, SectionSubheader, styles } from "components";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
const { colors } = styles;

export const RecommendationItem = ({ id, recommendation, rank, onRemove }) => {
  const animateLayoutChanges = (args) =>
    args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting } =
    useSortable({
      animateLayoutChanges,
      id,
    });

  const tierScores = [];
  const metCriteria = [];
  const unmetCriteria = [];

  for (const tier of recommendation.tiers) {
    tierScores.push(<div>Tier {tier.level}</div>);

    (tier?.criteria || []).forEach((criterion) => {
      if (criterion.score === 1) {
        metCriteria.push(<div key={recommendation.rank}>{criterion.name}</div>);
      } else {
        unmetCriteria.push(<div key={recommendation.rank}>{criterion.name}</div>);
      }
    });
  }

  const transitionStyles = {
    transform: CSS.Transform.toString(transform),
    transition,

    backgroundColor: isDragging ? "#f0f0f0" : "white",
    cursor: "grab",
    opacity: isDragging ? 0.5 : 1,
    boxShadow: isDragging ? "0 0 10px rgba(0,0,0,0.2)" : "none",
  };

  const renderCriteria = () => {
    return recommendation.tiers.flatMap((tier) =>
      (tier?.criteria || []).map((criterion, n) => (
        <div key={`criteria-${n}`} className="criteria-item">
          <div>{criterion.name}</div>
          <FontAwesomeIcon
            icon={criterion.score === 1 ? faCheck : faTimes}
            style={{ color: criterion.score === 1 ? 'green' : 'red' }}
          />
        </div>
      ))
    );
  };

  return (
    <StyledContainer>
      <Rank>{rank}</Rank>
      <StyledRecommendation
        style={transitionStyles}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <div>
          <StyledColumn>
            <SectionSubheader>{formatName(recommendation.provider)}</SectionSubheader>
          </StyledColumn>
        </div>
        <div>
          <StyledColumn>
          <Span>
            <div className="criteria-list">
              {renderCriteria()}
            </div>
          </Span>
          </StyledColumn>
        </div>
      </StyledRecommendation>
      <button
        className="close"
        style={isSorting ? { display: "none" } : { display: "block" }}
        onClick={() => {
          onRemove(recommendation.rank);
        }}
      >
        <CloseIcon />
      </button>
    </StyledContainer>
  );
};

const { sizing, weights, spacing } = styles;

const StyledColumn = styled.div`
  padding-top: ${spacing.halfSpace};
  padding-bottom: 0;
  text-align: left;

.criteria-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
  }

  .criteria-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    margin: ${spacing.singleSpace};
  }`
;

const StyledContainer = styled.div`
  display: flex;
  cursor: grab;
  align-items: flex-start;
  position: relative;
  flex-direction: row;
  margin-bottom: ${spacing.singleSpace};

  button.close {
    position: absolute;
    left: 93%;
    top: 0%;
    z-index: 1000;
    padding: 0;
    width: 20px;
    height: 20px;
  }
  .close svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledRecommendation = styled.div`
  border: 1px solid ${colors.darkGray};
  padding: ${spacing.singleSpace};
  width: 100%;
`;

const Span = styled.span`
  font-size: ${sizing.listTextSize};
  font-weight: ${weights.semiBold};
  margin-bottom: 0;
`;

const Rank = styled.div`
  font-size: ${sizing.sectionSubheaderTextSize};
  font-weight: ${weights.bold};
  margin-right: ${spacing.doubleSpace};
  align-self: center;
`;
