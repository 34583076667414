import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";

import {
  ErrorMessage,
  HttpClientContext,
  Input,
  Paragraph,
  PrimaryButton,
  styles,
  Subheader,
} from "components";
import styled from "styled-components";
import validator from "validator";

export const EmailCapture = ({ headline, category }) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { post } = useContext(HttpClientContext);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    if (!validator.isEmail(email)) {
      setError("Whoops! That email address doesn't look right, please check and try again.");
      setLoading(false);
      return;
    }
    try {
      await post({
        url: "users/waitlist/",
        payload: {
          category,
          company,
          email,
          first_name: firstName,
          last_name: lastName,
          tos_accepted: termsAccepted,
        },
      });
      setLoading(false);
      navigate("/waitlist-confirm");
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const valid = email && firstName && lastName && company && termsAccepted;
  return (
    <Container>
      <StyledEmailCapture>
        <div className="form-header">
          <Row>
            <Col xs={12} lg={{ offset: 3, span: 6 }}>
              <Subheader style={{ marginBottom: "45px" }}>Thanks for your interest!</Subheader>
              <Paragraph>
                We are currently in private beta with a small number of agencies. If you're
                interested in joining our beta program, fill out your information below and our team
                will be in touch.
              </Paragraph>
            </Col>
          </Row>
        </div>
        <div className="email-capture-form">
          <Row>
            <Col xs={12} lg={{ offset: 3, span: 6 }}>
              <Input
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
                type="text"
                value={firstName}
              />
              <Input
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
                type="text"
                value={lastName}
              />
              <Input
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Business Name"
                type="text"
                value={company}
              />
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your best email"
                type="email"
                value={email}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <label className="terms-checkbox">
                <Input type="checkbox" onChange={() => setTermsAccepted(!termsAccepted)} />
                <Paragraph>
                  I agree to the{" "}
                  <a
                    href="https://www.hellosanctum.com/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.hellosanctum.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Paragraph>
              </label>
              <StyledButton>
                <PrimaryButton
                  disabled={!valid || loading}
                  onClick={handleSubmit}
                  text={loading ? "Submitting..." : "SIGN UP"}
                />
              </StyledButton>
            </Col>
          </Row>
        </div>
      </StyledEmailCapture>
    </Container>
  );
};

const { colors, spacing } = styles;

const StyledEmailCapture = styled.div`
  border: 1px solid ${colors.lightGray};
  padding: ${spacing.quadSpace} ${spacing.doubleSpace};
  text-align: center;

  .email-capture-form {
    margin: ${spacing.doubleSpace} auto;
  }

  .terms-checkbox {
    input {
      height: 22px;
    }
    input,
    p {
      display: inline;
      font-size: 16px;
    }
  }

  input::placeholder {
    color: ${colors.mediumGray};
  }

  input.invalid {
    border: 1px solid ${colors.red};
  }

  input.valid {
    border: 1px solid ${colors.brightGreen};
  }

  h4 {
    margin-bottom: ${spacing.doubleSpace};
  }

  p {
    margin-bottom: ${spacing.doubleSpace};
  }
`;

const StyledButton = styled.div`
  width: 100%;

  button {
    width: 100%;
    background-color: ${colors.blue};
    margin-top: ${spacing.quadSpace};

    &:disabled {
      background-color: ${colors.blueOpaque};
    }
  }
`;
