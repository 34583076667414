import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Paragraph, styles, Subtitle } from "components";
import styled from "styled-components";

export const WaitlistConfirmation = () => {
  return (
    <StyledConfirmation>
      <Container>
        <Row>
          <Col lg={{ offset: 3, span: 6 }}>
            <StyledMessage>
              <Subtitle>Success!</Subtitle>
              <Paragraph>
                We received your request, and our team has been notified. If you're a fit for the
                beta program we'll reach out soon.
              </Paragraph>
            </StyledMessage>
          </Col>
        </Row>
      </Container>
    </StyledConfirmation>
  );
};

const { colors, spacing, screens } = styles;
const StyledConfirmation = styled.div`
  border-top: 1px solid ${colors.lightGray};
  padding: ${spacing.quadSpace} ${spacing.doubleSpace};
  text-align: center;
`;

const StyledMessage = styled.div`
  background-color: white;
  border-radius: 10px;
  color: black;
  padding: ${spacing.doubleSpace};
  margin: 0 auto;

  ${screens.md} {
    padding: ${spacing.tripleSpace};
    border-radius: 20px;
  }

  p {
    margin-top: ${spacing.doubleSpace};
  }

  button {
    margin-top: ${spacing.quadSpace};
  }
`;
