import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import {
  AuthProvider,
  BugsnagProvider,
  Footer,
  GlobalStyle,
  Header,
  HttpClientProvider,
  Logout,
  PrivateRoute,
  ScrollToTop,
} from "components";
import {
  Contact,
  HomePage,
  Login,
  LoginLink,
  NotFound,
  OffersForShift,
  PrivacyPolicy,
  ProviderRecommendations,
  ShiftConfirmation,
  ShiftDashboard,
  ShiftListConfirmation,
  ShiftOfferDetail,
  ShiftOffers,
  Signup,
  SplashPage,
  TermsAndConditions,
  Waitlist,
  WaitlistConfirmation,
  ZoomAuth,
} from "screens";
import { WithTracking } from "services/analytics";

function App() {
  const isProd = process.env.NODE_ENV === "production";

  return (
    <BugsnagProvider isProd={isProd}>
      <AuthProvider>
        <HttpClientProvider>
          <GlobalStyle />
          <Router>
            {isProd && <WithTracking />}
            <ScrollToTop />
            <Header />
            <Routes>
              {/* PUBLIC ROUTES */}
              <Route path="/" exact element={<SplashPage />} />
              <Route path="/confirmation/:id" element={<ShiftConfirmation />} />
              <Route path="/confirmation-set/:id" element={<ShiftListConfirmation />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/homecare" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/link" element={<LoginLink />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="/waitlist" element={<Waitlist />} />
              <Route path="/waitlist-confirm" element={<WaitlistConfirmation />} />
              {/* PRIVATE ROUTES */}
              <Route
                path="/offers/:shiftId"
                element={
                  <PrivateRoute>
                    <OffersForShift />
                  </PrivateRoute>
                }
              />
              <Route
                path="/provider-recommendations/:shiftId"
                element={
                  <PrivateRoute>
                    <ProviderRecommendations />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shift-dashboard"
                element={
                  <PrivateRoute>
                    <ShiftDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shift-offer/:id"
                element={
                  <PrivateRoute>
                    <ShiftOfferDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/shift-offers"
                element={
                  <PrivateRoute>
                    <ShiftOffers />
                  </PrivateRoute>
                }
              />
              <Route
                path="/zoom/auth"
                element={
                  <PrivateRoute>
                    <ZoomAuth />
                  </PrivateRoute>
                }
              />
              {/* 404 */}
              <Route path="/*" element={<NotFound />} />
            </Routes>
            <Footer />
          </Router>
        </HttpClientProvider>
      </AuthProvider>
    </BugsnagProvider>
  );
}

export default App;
