import React, { useState } from "react";

import { formatDateTime, formatName } from "utils";

import { ActionButton, Paragraph, styles } from "components";
import { Greeting, ShiftTitle } from "screens/ShiftConfirmation";
import styled from "styled-components";

export const CONFIRMATION_ACTIONS = {
  accept: "accept",
  decline: "decline",
};

export const ShiftConfirmationCard = ({
  confirmation,
  error,
  loading,
  showGreeting = true,
  submit,
  submittedAction,
}) => {
  if (!submittedAction) {
    return (
      <ShiftDetails
        confirmation={confirmation}
        error={error}
        loading={loading}
        showGreeting={showGreeting}
        submit={submit}
      />
    );
  } else {
    return <ActionConfirmation submittedAction={submittedAction} />;
  }
};

const ActionConfirmation = ({ submittedAction }) => {
  if (submittedAction === CONFIRMATION_ACTIONS.accept) {
    return (
      <StyledActionCard>
        <Paragraph>
          Great thank you! Please remember to bring hydration and a meal, and avoid wearing hats,
          scarves, or dew rags.
        </Paragraph>
      </StyledActionCard>
    );
  }
  if (submittedAction === CONFIRMATION_ACTIONS.decline) {
    return (
      <StyledActionCard>
        <Paragraph>
          Understood, your cancellation is pending review. Please call the office to confirm your
          cancellation.
        </Paragraph>
      </StyledActionCard>
    );
  }
};

const ShiftDetails = ({ confirmation, error, loading, showGreeting, submit }) => {
  const { ErrorMessage } = styles;
  const { shift } = confirmation;
  const { company, provider, recipient } = shift;
  const { month, day, hours, minutes, period } = formatDateTime(confirmation.shift.start_date);
  const {
    hours: endHours,
    minutes: endMinutes,
    period: endPeriod,
  } = formatDateTime(confirmation.shift.end_date);

  return (
    <StyledActionCard>
      {showGreeting ? <Greeting confirmation={confirmation} /> : <ShiftTitle shift={shift} />}
      <ShiftConfirmationFlow
        company={company}
        confirmationId={confirmation.id}
        loading={loading}
        submit={submit}
      />
      <Paragraph className="title">Shift Details</Paragraph>
      <ul>
        <li>
          <Paragraph>{formatName(recipient)}</Paragraph>
        </li>
        <li>
          <Paragraph>
            {month} {day}
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            {hours}:{minutes} {period} - {endHours}:{endMinutes} {endPeriod}
          </Paragraph>
        </li>
        <li>
          <Paragraph>
            {recipient.address.street_address}, {recipient.address.city}
          </Paragraph>
        </li>
        {provider.rates ? (
          <li>
            <Paragraph>{provider.rates[0]}</Paragraph>
          </li>
        ) : null}
      </ul>
      <ShiftNotes shift={confirmation.shift} />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledActionCard>
  );
};

const ShiftConfirmationFlow = ({ company, confirmationId, loading, submit }) => {
  const { Paragraph } = styles;

  const [cancelSelected, setCancelSelected] = useState(false);
  const [cancelConfirmed, setCancelConfirmed] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  if (cancelConfirmed) {
    return (
      <StyledShiftConfirmationFlow>
        <Paragraph>
          Ok I'll need to explain to the staffing team why you're cancelling. Please provide a brief
          explanation below.
        </Paragraph>
        <StyledCancellationReason>
          <textarea
            autoFocus
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          />
        </StyledCancellationReason>
        <ActionButton
          className="decline"
          disabled={loading}
          onClick={submit({
            action: CONFIRMATION_ACTIONS.decline,
            detail: cancelReason,
            id: confirmationId,
          })}
          text={loading ? "Submitting..." : "Submit and cancel"}
        />
        <ActionButton
          className="accept"
          disabled={loading}
          onClick={submit({ action: CONFIRMATION_ACTIONS.accept, id: confirmationId })}
          text={loading ? "Submitting..." : "Nevermind, I can make it!"}
        />
      </StyledShiftConfirmationFlow>
    );
  }

  if (cancelSelected) {
    return (
      <StyledShiftConfirmationFlow>
        <Paragraph>
          Are you sure you want to cancel? If you cancel, the staffing team at {company.name} will
          be notified.
        </Paragraph>
        <ActionButton
          className="decline"
          disabled={loading}
          onClick={() => {
            setCancelConfirmed(true);
          }}
          text={loading ? "Submitting..." : "Yes I'm sure, cancel my shift"}
        />
        <ActionButton
          className="accept"
          disabled={loading}
          onClick={submit({ action: CONFIRMATION_ACTIONS.accept, id: confirmationId })}
          text={loading ? "Submitting..." : "Nevermind, I can make it!"}
        />
      </StyledShiftConfirmationFlow>
    );
  }

  return (
    <StyledShiftConfirmationFlow>
      <ActionButton
        className="accept"
        disabled={loading}
        onClick={submit({ action: CONFIRMATION_ACTIONS.accept, id: confirmationId })}
        text={loading ? "Submitting..." : "Yes I'll be there!"}
      />
      <ActionButton
        className="decline"
        disabled={loading}
        onClick={() => {
          setCancelSelected(true);
        }}
        text={loading ? "Submitting..." : "Cancel"}
      />
    </StyledShiftConfirmationFlow>
  );
};

const ShiftNotes = ({ shift }) => {
  const tags = JSON.parse(shift.recipient.tags);
  if (!tags?.notes) {
    return null;
  }

  return (
    <StyledShiftNotes>
      <Paragraph className="title">Notes</Paragraph>
      {Object.entries(tags.notes).map(([key, value]) => (
        <Paragraph key={key}>
          {key} {value}
        </Paragraph>
      ))}
    </StyledShiftNotes>
  );
};

const { spacing, colors } = styles;
const StyledActionCard = styled.div`
  padding: ${spacing.doubleSpace};

  ul {
    margin-bottom: ${spacing.tripleSpace};
    li p {
      font-weight: 500;
    }
  }

  p.title {
    font-weight: 700;
  }

  button {
    display: block;
    width: 100%;
    margin-bottom: ${spacing.singleSpace};
    padding: ${spacing.singleSpace};

    &.accept {
      color: white;
      background-color: ${colors.green};
    }
    &.decline {
      color: black;
      background-color: white;
      border: 1px solid ${colors.mediumGray};
    }
  }
`;

const StyledShiftConfirmationFlow = styled.div`
  margin-bottom: ${spacing.quadSpace};
`;

const StyledCancellationReason = styled.div`
  textarea {
    width: 100%;
    padding: ${spacing.singleSpace};
    margin-bottom: ${spacing.doubleSpace};
    border: 1px solid ${colors.lightGray};
    border-radius: 20px;
    min-height: 120px;

    &:focus {
      outline: none;
    }
  }
`;

const StyledShiftNotes = styled.div`
  margin-bottom: ${spacing.tripleSpace};
`;
