import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
  Subheader,
  Paragraph,
  styles,
  PrimaryButton,
} from 'components';
const { colors, spacing } = styles;

export const Confirmation = () => {
  return (
    <Container>
      <Row>
        <Col>
          <StyledConfirmation>
            <StyledSummary>
              <Subheader className='header'>Success!</Subheader>
              <Paragraph>
                Offers have been sent. We'll keep you
                notified as we hear back from care providers.
              </Paragraph>
              <PrimaryButton
                text={'Back to Dashboard'}
                link='/shift-dashboard'
              />
            </StyledSummary>
          </StyledConfirmation>
        </Col>
      </Row>
    </Container>
  );
};

const StyledConfirmation = styled.div`
  border: 1px solid ${colors.mediumGray};
  padding: ${spacing.doubleSpace};
  border-radius: 5px;
  margin-bottom: ${spacing.doubleSpace};

  .header, p {
    margin-bottom: ${spacing.doubleSpace};
  }
`;

const StyledSummary = styled.div`
  margin: ${spacing.singleSpace} 0;
`;
