import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { ErrorMessage, HttpClientContext, Paragraph, styles } from "components";
import styled from "styled-components";

import { Shift, StyledColumn } from "./Shift";


const shiftStates = [
  "provider_scheduled",
  "booking_confirmation_sent",
  "booking_confirmation_confirmed",
  "booking_confirmation_timed_out",
  "booking_confirmation_declined",
  "early_reminder_sent",
  "early_reminder_confirmed",
  "early_reminder_timed_out",
  "early_reminder_declined",
  "day_before_reminder_sent",
  "day_before_reminder_confirmed",
  "day_before_reminder_timed_out",
  "day_before_reminder_declined",
  "declined",
  "sourcing_provider",
  "in_progress",
  "completed",
];


export const ShiftList = () => {
  const { get } = useContext(HttpClientContext);
  const [error, setError] = useState("");
  const [shifts, setShifts] = useState([]);

  useEffect(() => {
    const today = new Date();
    const startDate = today.toISOString();

    const getShifts = async () => {
      try {
        const res = await get({
          url: "shifts/status/",
          params: {
            state: shiftStates,
            start_date: startDate,
          },
        });
        setShifts(res.data?.shifts || []);
      } catch (err) {
        setError(err.message);
      }
    };

    getShifts();
  }, [get]);

  return (
    <StyledShiftList>
      <Container fluid>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Row className="header">
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Status</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Caregiver</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Patient</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Shift Date</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 2 }}>
            <StyledColumn>
              <Paragraph>Shift Time</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Booking Confirmed</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Early Reminder</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Final Reminder</Paragraph>
            </StyledColumn>
          </Col>
          <Col xs={{ span: 1 }}>
            <StyledColumn>
              <Paragraph>Actions</Paragraph>
            </StyledColumn>
          </Col>
        </Row>
        {shifts && shifts.map((item, i) => <Shift key={i} setError={setError} {...item} />)}
      </Container>
    </StyledShiftList>
  );
};

const { spacing } = styles;
const StyledShiftList = styled.section`
  padding: ${spacing.doubleSpace} 0;
  .header {
    p {
      font-weight: 600;
    }
  }
`;
