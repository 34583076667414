import React, { useEffect, useContext, useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';
import {
  ErrorMessage,
  HttpClientContext,
  Paragraph,
  styles,
  PrimaryButton,
} from 'components';
const { colors, spacing } = styles;

export const Incentives = ({
  onIncentiveSubmitted,
  selectedIncentiveUnit,
  selectedIncentiveValue,
  shiftId,
  providerIds
}) => {
  const { post } = useContext(HttpClientContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [incentiveUnit, setIncentiveUnit] = useState(selectedIncentiveUnit);
  const [incentiveValue, setIncentiveValue] = useState(selectedIncentiveValue);
  const [summary, setSummary] = useState('');
  const [offersData, setOffersData] = useState([]);

  const incentiveValues = useRef([
    {value: 25.00, label: '25'},
    {value: 50.00, label: '50'},
    {value: 75.00, label: '75'},
    {value: 100.00, label: '100'},
    {value: 150.00, label: '150'},
    {value: 200.00, label: '200'},
  ]);

  const incentiveUnits = useRef([
    {value: 'cash', label: 'Dollars'},
    {value: 'points', label: 'Points'},
    {value: 'vacation_days', label: 'Vacation Days'},
  ]);

  useEffect(() => {
    const generateOffers = async () => {
      setLoading(true);
      try {
        const offersData = await post({
          url: '/offers/',
          payload: {
            shift_id: shiftId,
            providers: providerIds,
          }
        });
        const data = offersData.data?.offers || [];
        setOffersData(data)
        if (data.length > 0 && data[0].incentive) {
          const { unit, value, justification } = data[0].incentive;
          const mappedUnit = incentiveUnits.current.find(option => option.value === unit);
          const mappedValue = incentiveValues.current.find(option => option.value === Number(value));
          setSummary(
            `Because ${justification}, the recommended incentive is
            ${value} ${mappedUnit.label}.`
          )
          setIncentiveValue(mappedValue);
          setIncentiveUnit(mappedUnit);
        } else {
          setSummary(
            `This shift does not meet any of your incentive criteria,
            so there are no recommended incentives.`
        )
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    generateOffers();
  }, [post, shiftId, providerIds]);

  const submit = async () => {
    const updatedOffers = offersData.map(offer => ({
      ...offer,
      incentive: {
        ...offer.incentive,
        unit: incentiveUnit.value,
        value: incentiveValue.value,
      },
    }));

    try {
      await post({
        url: '/offers/send/',
        payload: {
          'offers': updatedOffers
        },
      });
      onIncentiveSubmitted();
    } catch (error) {
      setError(error.message);
    }
  }

  if (loading) {
    return (
      <Container>
        <StyledShiftOffers>
          <Row>
            <Col>
              <StyledSummary>
                <Paragraph>Loading...</Paragraph>
              </StyledSummary>
            </Col>
          </Row>
        </StyledShiftOffers>
      </Container>
    )
  }

  return (
    <Container>
      <StyledShiftOffers>
      <h2>Provider Incentives</h2>
      <Row>
        <Col>
            <StyledSummary>
              {error ? <ErrorMessage error={error}>{error}</ErrorMessage> : null}
              <Paragraph>
                {summary} You can update the incentive below. When you're ready click
                "Send" to send the offer to the recommended providers.
              </Paragraph>
            </StyledSummary>
            <Paragraph>
            <StyledSelectWrapper>
                <StyledSelect
                  value={incentiveValue}
                  onChange={(value) => setIncentiveValue(value)}
                  options={incentiveValues.current}
                />
                <StyledSelect
                  value={incentiveUnit}
                  onChange={(value) => setIncentiveUnit(value)}
                  options={incentiveUnits.current}
                />
              </StyledSelectWrapper>
              </Paragraph>
        </Col>
      </Row>
      <Row>
        <Col>
          <PrimaryButton
            onClick={submit}
            text={"Send"}
          />
        </Col>
      </Row>
      </StyledShiftOffers>
    </Container>
  );
};

const StyledShiftOffers = styled.div`
  border: 1px solid ${colors.mediumGray};
  padding: ${spacing.quadSpace} ${spacing.doubleSpace};
  border-radius: 5px;
  margin-bottom: ${spacing.doubleSpace};

  select {

  }
`;

const StyledSummary = styled.div`
  margin: ${spacing.doubleSpace} 0;
`;

const StyledSelectWrapper = styled.div`
  display: flex;
  gap: ${spacing.singleSpace};
  margin-bottom: ${spacing.doubleSpace};
`;

const StyledSelect = styled(Select)`
  max-width: 220px;
  flex: 1;
`;
