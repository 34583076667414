import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { formatDateTime, formatName } from "utils";

import {
  Paragraph,
  styles,
  StyledDropdown
} from "components";
import styled from "styled-components";

const ShiftOfferStates = {
    sent: 'Sent',
    accepted: 'Accepted',
    declined: 'Declined',
    closed: 'Closed',
    not_sent: 'Not Sent',
};

const offerSentAt = (dateTime) => {
  const { month, day, hours, minutes, period } = formatDateTime(dateTime);
  const offerSentDate = `${month} ${day}`;
  const offerSentTime = `${hours}:${minutes} ${period}`;
  return {
    offerSentDate,
    offerSentTime,
  }
}

export const Offer = ({
  id,
  setError,
  provider,
  shift,
  state,
  sent_at,
}) => {

  const { offerSentDate, offerSentTime } = offerSentAt(sent_at);

  const onChange = () => {
    console.log('update offer state...');
  }

  return (
    <StyledOffer>
      <Row>
        <Col xs={{ span: 3 }}>
          <StyledColumn>
            <Paragraph>{formatName(provider)}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 3 }}>
          <StyledColumn>
            <Paragraph>{offerSentDate}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 3 }}>
          <StyledColumn>
            <Paragraph>{offerSentTime}</Paragraph>
          </StyledColumn>
        </Col>
        <Col xs={{ span: 3 }}>
          <StyledColumn>
            <OfferState
              messages={[]}
              currentState={state}
              onChange={onChange}
              options={ShiftOfferStates}
            />
          </StyledColumn>
        </Col>
      </Row>
    </StyledOffer>
  );
};

const OfferState = ({ currentState, onChange, options }) => {
  return (
    <div>
      <StyledDropdown
        id="offer-state"
        value={currentState}
        onChange={(event) => onChange(event.target.value)}
      >
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </StyledDropdown>
    </div>
  );
};

const { spacing, colors } = styles;
export const StyledColumn = styled.div`
  padding-top: ${spacing.doubleSpace};
  padding-bottom: ${spacing.doubleSpace};
  text-align: left;

  p.alert {
    color: ${colors.red};
  }

  &.declined {
    p,
    select {
      color: ${colors.red};
      font-weight: bold;
    }
  }

  &.ok {
    p,
    select {
      color: ${colors.green};
    }
  }

  &.at-risk {
    p,
    select {
      color: ${colors.orange};
    }
  }

  &.not-sent {
    background-color: none;
  }

  button {
    border: none;
    background: none;
    display: inline-block;
    margin-top: ${spacing.singleSpace};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledOffer = styled.div`
  border-bottom: 1px solid ${colors.lightGray};

  &.cancelled {
    color: ${colors.mediumGray} !important;

    select {
      color: ${colors.mediumGray} !important;
      font-weight: normal;
    }
  }

  p {
    font-size: 16px;
  }
`;
