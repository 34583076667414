import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext, EmailCapture, styles } from "components";
import styled from "styled-components";

import { Hero, ProblemStatement, Result, Solution, Testimonials } from "./components";

export const HomePage = (props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/caregiver-status");
  }, [navigate, user]);

  return (
    <StyledHomePage>
      <Hero />
      <ProblemStatement />
      <Solution />
      <Testimonials />
      <Result />
      <EmailCapture headline={"Join the Waitlist"} category="inbox-demo-0.1" />
    </StyledHomePage>
  );
};

const { spacing } = styles;
const StyledHomePage = styled.div`
  padding: ${spacing.quadSpace} 0;
`;
