import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { Paragraph, styles, ThreePartHeader } from "components";
import styled from "styled-components";

const t1 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/sarah-z.jpeg`;
const t2 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/nikolai-d.png`;
const t3 = `${process.env.REACT_APP_ASSETS_URL}/img/homepage/diana-r.jpg`;

export const Testimonials = () => {
  return (
    <StyledTestimonials>
      <Container fluid>
        <ThreePartHeader title="What our customers are saying" />
        <Testimonial
          image={t1}
          name="Sarah Z"
          quote="Oh my gosh I’ve been wanting this for years. This is crazy."
        />
        <Testimonial
          image={t2}
          name="Nikolai D"
          quote="It feels like my inbox just shaved its head after rocking a combover for years."
        />
        <Testimonial image={t3} name="Diana R" quote="This is a whole new way of life." />
      </Container>
    </StyledTestimonials>
  );
};

const Testimonial = ({ image, name, theme, quote }) => {
  return (
    <Row>
      <Col md={{ offset: 2, span: 10 }}>
        <StyledTestimonial className={theme}>
          <img src={image} alt="Customer" />
          <div className="content-container">
            <Paragraph className="large">&ldquo;{quote}&rdquo;</Paragraph>
            <Paragraph className="small">{name}</Paragraph>
          </div>
        </StyledTestimonial>
      </Col>
    </Row>
  );
};

const { spacing, screens } = styles;
const StyledTestimonials = styled.section`
  margin: ${spacing.quadSpace} 0;
  margin-bottom: 120px;
  .divider {
    margin-bottom: ${spacing.doubleSpace};
  }
`;

const StyledTestimonial = styled.div`
  padding: ${spacing.quadSpace} ${spacing.doubleSpace} ${spacing.singleSpace};
  text-align: center;

  img {
    margin-bottom: ${spacing.singleSpace};
    object-fit: cover;
    border-radius: 50%;
    height: 160px;
    width: 160px;
  }

  ${screens.md} {
    text-align: left;

    .content-container {
      margin-left: ${spacing.doubleSpace};
      max-width: 70%;
    }

    img,
    .content-container {
      display: inline-block;
    }
  }
`;
