import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import {
  ErrorMessage,
  HttpClientContext,
  Input,
  PageHeader,
  Paragraph,
  PrimaryButton,
  styles,
} from "components";
import styled from "styled-components";

export const Waitlist = () => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const { post } = useContext(HttpClientContext);

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    try {
      await post({
        url: "users/waitlist/",
        payload: {
          email,
          first_name: firstName,
          last_name: lastName,
          tos_accepted: true,
        },
      });
      setLoading(false);
      setFirstName("");
      setLastName("");
      setEmail("");
      setSuccessMsg("Success! You're on the list.");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <StyledSignup>
      <Container>
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <StyledHeader>
              <PageHeader>Sign up for Online Safety Training</PageHeader>
            </StyledHeader>
            <StyledLoginForm>
              <Paragraph className="large">
                Sanctum offers free 1-hour seminars that cover key topics for staying safe online.
                Enter your email below and one of our team members will reach out with details on
                the next available seminar.
              </Paragraph>
              <Input
                autoFocus
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First name"
                type="text"
                value={firstName}
              />
              <Input
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last name"
                type="text"
                value={lastName}
              />
              <Input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@example.com"
                type="email"
                value={email}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {successMsg ? <StyledMessage>{successMsg}</StyledMessage> : null}
              <StyledButton>
                <PrimaryButton disabled={!email || loading} onClick={handleSubmit} text="Submit" />
              </StyledButton>
            </StyledLoginForm>
          </Col>
        </Row>
      </Container>
    </StyledSignup>
  );
};

const { colors, spacing } = styles;
const StyledSignup = styled.div`
  border-top: 1px solid ${colors.blue};
  height: 100vh;
  padding: ${spacing.quadSpace} 0;
`;

const StyledHeader = styled.div`
  margin-bottom: ${spacing.quadSpace};
`;

const StyledLoginForm = styled.div`
  background-color: white;
  box-shadow: 0 2px 3px ${colors.gray};
  padding: ${spacing.doubleSpace};

  p {
    margin-bottom: ${spacing.doubleSpace};
  }

  input.invalid {
    border: 1px solid ${colors.red};
  }

  input.valid {
    border: 1px solid ${colors.green};
  }
`;

const StyledButton = styled.div`
  margin-bottom: ${spacing.tripleSpace};
  width: 100%;

  button {
    width: 100%;
  }
`;

const StyledMessage = styled.p`
  padding: ${spacing.doubleSpace};
  background-color: ${colors.green};
  color: white;
  border-radius: 25px;
  margin: ${spacing.doubleSpace} 0;
`;
