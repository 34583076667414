import React, { useCallback, useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  AuthContext,
  ErrorMessage,
  HttpClientContext,
  Input,
  Paragraph,
  PrimaryButton,
  SectionSubheader,
  styles,
} from "components";
import styled from "styled-components";

export const LoginLink = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { post } = useContext(HttpClientContext);
  const { loggedIn } = useContext(AuthContext);
  const [sent, setSent] = useState();

  const jobId = searchParams.get("job_id");
  const redirect = decodeURIComponent(searchParams.get("redirect") || "/caregiver-status");

  const createLoginLink = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const res = await post({
        url: "users/link/create",
        payload: { email, jobId, redirect },
      });
      if (res.status !== 201) {
        throw Error("System error. Please contact support");
      }
      setLoading(false);
      setSent(true);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }, [email, jobId, post, redirect]);

  useEffect(() => {
    if (loggedIn) {
      navigate(redirect);
    }

    if (jobId) createLoginLink();
  }, [redirect, loggedIn, navigate, jobId, createLoginLink]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createLoginLink();
  };

  return (
    <StyledLogin>
      <Container>
        <Row>
          <Col md={{ offset: 2, span: 8 }} lg={{ offset: 3, span: 6 }}>
            <StyledLoginForm onSubmit={handleSubmit}>
              <div style={{ marginBottom: styles.spacing.singleSpace }}>
                <SectionSubheader className="large">
                  {sent ? "Your login link has been sent!" : "We'll email you a login link"}
                </SectionSubheader>
              </div>
              {sent ? (
                <div>
                  <Paragraph>The link will be valid for 15 minutes.</Paragraph>
                  <Paragraph>
                    If you don't receive an email, please verify your account email.
                  </Paragraph>
                </div>
              ) : (
                <>
                  <Input
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email@example.com"
                    type="email"
                    value={email}
                  />
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                  <StyledButton>
                    <PrimaryButton
                      text={loading ? "Loading..." : "Send"}
                      disabled={!email || loading}
                      onClick={handleSubmit}
                      role="submit"
                    />
                  </StyledButton>
                </>
              )}
            </StyledLoginForm>
          </Col>
        </Row>
      </Container>
    </StyledLogin>
  );
};

const { colors, spacing } = styles;
const StyledLogin = styled.div`
  background-color: ${colors.blue};
  padding: ${spacing.quadSpace} 0;
  min-height: 100vh;
`;

const StyledLoginForm = styled.form`
  background-color: white;
  border-radius: ${spacing.halfSpace};
  padding: ${spacing.doubleSpace};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled.div`
  // margin-bottom: ${spacing.singleSpace};
  width: 100%;

  button {
    width: 100%;
  }
`;
