import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";

import { formatDateTime, formatName } from "utils";

import {
  ErrorMessage,
  HttpClientContext,
  Paragraph,
  PrimaryButton,
  styles,
  Subheader,
} from "components";
import styled from "styled-components";

import { RecommendationList } from "./components/RecommendationList";

export const ProviderRecommendations = () => {
  const { shiftId } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shift, setShift] = useState(null);
  const [recommendations, setRecommendations] = useState([]);

  const { get } = useContext(HttpClientContext);

  const providerIds = recommendations.map((rec) => rec.provider.id).join(',');

  useEffect(() => {
    const getShiftRecommendations = async () => {
      setLoading(true);
      try {
        const res = await get({
          url: `offers/${shiftId}/provider-recommendations/`,
        });
        const recommendations = (res.data?.recommendations || []).filter((recommendation) => {
          return recommendation?.provider?.first_name && recommendation?.provider?.last_name;
        });

        setRecommendations(recommendations || []);
        setShift(res.data?.shift || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getShiftRecommendations();
  }, [get, shiftId]);

  if (loading || !shift) {
    return (
      <StyledLaunchPad>
        <Container>
          <Row>
            <Col xs={12} md={{ offset: 3, span: 6}}>
              <Subheader>Loading shift details...</Subheader>
            </Col>
          </Row>
        </Container>
      </StyledLaunchPad>
    );
  }

  const { month, day, hours, minutes, period } = formatDateTime(shift.start_date);

  return (
    <StyledLaunchPad>
      <Container>
        <Row>
          <Col lg={{ offset: 1, span: 10 }}>
            <StyledPlanSection>
              <Subheader className='header'>Fill Shift</Subheader>
              <Paragraph>
                {formatName(shift?.provider)} can't make their shift with {formatName(shift?.recipient)}{" "}
                on {month} {day} at {hours}:{minutes} {period}
              </Paragraph>
              <Paragraph>
                The providers below are the best match for {formatName(shift?.recipient)}.
                You can change the order and remove any provider below.
                Once you're satisfied with the list, click confirm.
              </Paragraph>
              <PrimaryButton
                link={`/shift-offers?providers=${providerIds}&shiftId=${shiftId}`}
                text='Confirm'
              />
            </StyledPlanSection>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={{ offset: 1, span: 10 }}>
            <StyledPlanSection>
              <Subheader className='header'>Recommended providers:</Subheader>
              <RecommendationList recommendations={recommendations} />
            </StyledPlanSection>
          </Col>
        </Row>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Container>
    </StyledLaunchPad>
  );
};

const { spacing, colors } = styles;

const StyledLaunchPad = styled.div`
  padding: ${spacing.quadSpace} 0 ${spacing.doubleSpace};

  .header {
    margin-bottom: ${spacing.doubleSpace};
  }

  p.started,
  li.started,
  span.started {
    color: ${colors.mediumGray};
  }

  select,
  span {
    display: inline-block;
    margin-bottom: ${spacing.singleSpace};
  }

  button {
    width: 200px;
    padding: ${spacing.singleSpace};
    margin-top: ${spacing.doubleSpace};
  }

  .modify,
  .row {
    margin-left: ${spacing.singleSpace};
  }

  a {
    text-decoration: none;
  }

  h4 {
    margin-bottom: ${spacing.doubleSpace};
  }

  ul,
  ol {
    margin-bottom: ${spacing.doubleSpace};
  }

  p,
  li {
    margin-bottom: ${spacing.singleSpace};
  }
`;

const StyledPlanSection = styled.div`
  border: 1px solid ${colors.mediumGray};
  padding: ${spacing.doubleSpace};
  border-radius: 5px;
  margin-bottom: ${spacing.doubleSpace};

  .header {
    margin-bottom: ${spacing.doubleSpace};
  }
`;
